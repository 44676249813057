import { ManualChargeDetailEntity } from "./ManualChargeDetailEntity"

export interface ManualChargeHeaderEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
	manChargeNo: string | null,
    chargeCategory: string | null,
    billToCompany: string | null,
    customerCode: string | null,
    currencyCode: string | null,
    chargeType: string | null,
    subChargeType: string | null,
    consortiumCode: string | null,
    vesselCode: string | null,
    voyageCode: string | null,
    vesselName: string | null,
    etd: Date | null,
    eta: Date | null,
    etb: Date | null,
    loa: number | null,
    soa: string | null,
    serviceCode: string | null,
    operatingTml: string | null,
    opsDate: Date | null,
    poNo: string | null,
    ourRef: string | null,
    yourRef: string | null,
    slVesselCode: string | null,
    slIbVoyageCode: string | null,
    slObVoyageCode: string | null,
    hdrDesc1: string | null,
    hdrDesc2: string | null,
    berthingDatetimeFrom: Date | null,
    berthingDatetimeTo: Date | null,
    vslStartOperationDatetime: Date | null,
    vslEndOperationDatetime: Date | null,
    remarks: string | null,
    subject: string | null,
    chargeDesc: string | null,
    manualChargeDetails: ManualChargeDetailEntity[] | null,
    hdrState: 'NIL' | 'CFM' | 'TML' | 'INV' | 'STD',
    confirmedDatetime: Date | null,
    confirmedBy: string | null,
	originalDocNo: string | null,
	recalByBillToCompany : string | null,
    masterManualChargeHeaderId: string | null,
	isValid: string | null,  
	docNo: string | null,
	docHdrId: string | null,
	manDocNo: string | null,
	manDraftDocNo: string | null,
	creDocNo: string | null,
	creDraftDocNo: string | null,
	manDocDate: Date | null,
	creditNoteDate: Date | null,
	exchangeRateRefDate: Date | null,
	exchangeRate: number | null,
	convertedCurrencyCode: string | null,
	localCurrencyCode: string | null,
	localExchangeRate: number | null,
	estExchangeRate: number | null,
	orderNo: string | null,
	handlingTerminal: string | null,
	billingCycle: string | null,
	billingType: string | null,
	billingCycleCode: string | null,
	opsDateFrom: Date | null,
	opsDateTo: Date | null,
	discountType: string | null,
    
    nonOpsType: 'NONOPS' | 'STDBILL',
    //stdBill: ManualChargeStdBillEntity,    // CommonField will cut the field e.g. stdBill.billingCycle => billingCycle
    stdBillBillingCycle: string | null,
    stdBillServiceDate: Date | null,
    stdBillEffectiveDate: Date | null,
    stdBillExpiryDate: Date | null,
    stdBillSeqNo: number | null,

    customerName: string | null,

    [key: string]: string | boolean | null | Object | number | undefined
}

export const EMPTY_MANUAL_CHARGE_HEADER_ENTITY : ManualChargeHeaderEntity = {
    id: null,
    versionIdentifier: {
        version: undefined
    },
    manChargeNo: null,
    chargeCategory: null,
    billToCompany: null,
    customerCode: null,
    currencyCode: null,
    chargeType: null,
    subChargeType: null,
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
    vesselName: null,
    etd: null,
    eta: null,
    etb: null,
    loa: null,
    soa: null,
    serviceCode: null,
    operatingTml: null,
    opsDate: null,
    poNo: null,
    ourRef: null,
    yourRef: null,
    slVesselCode: null,
    slIbVoyageCode: null,
    slObVoyageCode: null,
    hdrDesc1: null,
    hdrDesc2: null,
    berthingDatetimeFrom: null,
    berthingDatetimeTo: null,
    vslStartOperationDatetime: null,
    vslEndOperationDatetime: null,
    remarks: null,
    subject: null,
    chargeDesc: null,
    manualChargeDetails: null,
    hdrState: 'NIL',
    confirmedDatetime: null,
    confirmedBy: null,
    originalDocNo: null,
    recalByBillToCompany: null,
    masterManualChargeHeaderId: null,
    isValid: null,
    docNo: null,
    docHdrId: null,
    manDocNo: null,
    manDraftDocNo: null,
    creDocNo: null,
    creDraftDocNo: null,
    manDocDate: null,
    creditNoteDate: null,
    exchangeRateRefDate: null,
    exchangeRate: null,
    convertedCurrencyCode: null,
    localCurrencyCode: null,
    localExchangeRate: null,
    estExchangeRate: null,
    orderNo: null,
    handlingTerminal: null,
    billingCycle: null,
    billingType: null,
    billingCycleCode: null,
    opsDateFrom: null,
    opsDateTo: null,
    discountType: null,
    nonOpsType: 'NONOPS',
    // stdBill: EMPTY_MANUAL_CHARGE_STD_BILL_ENTITY,
    customerName: null,
    stdBillBillingCycle: null,
    stdBillServiceDate: null,
    stdBillEffectiveDate: null,
    stdBillExpiryDate: null,
    stdBillSeqNo: null
}