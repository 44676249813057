import { FC } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";

type Props = {
    isShowBackButton?: boolean;
    callback: () => void;
    children?: React.ReactNode;
};

export const HeaderWithBackButton: FC<Props> = ({ isShowBackButton = true, callback, children = 'RECOVERY' }) => {
    if (!isShowBackButton) return <div className='im-header-with-back-button'>{children}</div>;
    
    return <div className='im-header-with-back-button'>
        <IconButton fileName='Icon-arrow-left' toolTipText='Back' toolTipPlacement="right" onClick={callback} />
        <div>{children}</div>
    </div>
}
