import * as yup from 'yup';
import { MessageConstant } from '../MessageConstant';

const message = MessageConstant.common;

export const oneOffRateValidation: { [x: string]: any; } = {
  rate: yup.number().required(message.MISSING),
  reasonCode: yup.string().required(message.MISSING),
};

export const accountTerminalValidation: { [x: string]: any; } = {
  reasonCode: yup.string().required(message.MISSING),
}

export const adjustmentValidation: { [x: string]: any; } = {
  adjustmentType: yup.string().required(message.MISSING),
  reasonCode: yup.string().required(message.MISSING),
};

export const updateOneOffRateValidation: { [x: string]: any; } = {
  ...oneOffRateValidation,
};

export const updateAccountTerminalValidation: { [x: string]: any; } = {
  ...accountTerminalValidation,
};

export const updateAdjustmentValidation: { [x: string]: any; } = {
  ...adjustmentValidation,
};

export const updateOneOffRateValidationSchema = yup.object().shape(updateOneOffRateValidation);
export const updateAccountTerminalValidationSchema = yup.object().shape(updateAccountTerminalValidation);
export const updateAdjustmentValidationSchema = yup.object().shape(updateAdjustmentValidation);