import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { ManualChargeDetailAdjustmentRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailAdjustmentRequestEntity";
import { ManualChargeDetailApplyRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailApplyRequestEntity";
import { ManualChargeDetailCntrEntity } from "domain/entity/ManualCharge/ManualChargeDetailCntrEntity";
import { ManualChargeDetailCntrRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailCntrRequestEntity";
import { ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { ManualChargeDetailRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailRequestEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ManualChargeDetailRepository } from "./ManualChargeDetailRepo";

export const ManualChargeDetailRepoImpl = () : ManualChargeDetailRepository => {
    const getDtlByHdrIdUrl = "/v1/manualChargeDetail/manChgHdrId";
    const saveDtlUrl = "/v1/manualChargeDetail/save";
    const saveAllUrl = "/v1/manualChargeDetail/saveAll";
    const replaceDtlUrl = "/v1/manualChargeDetail/replace";
    const applyUrl = "/v1/manualChargeDetail/apply";
    const deleteUrl = "/v1/manualChargeDetail/delete";
    const getDtlAttMsgUrl = "/v1/manualChargeDetail/attachment";
    const adjustmentUrl = "/v1/manualChargeDetail/adjustmentManualCharge";

    /**
     * Get the manual charge or Non-Ops charge by manual charge header id
     * @param manHdrId 
     * @returns 
     */
    const getManualChargeDetailByHdrId = async (manHdrId: number) : Promise<ManualChargeDetailEntity[]> => {
        let res = null;
        try {
            res = await  axiosGetData(chargeAxiosInstance, `${getDtlByHdrIdUrl}`, [manHdrId.toString()]) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
        if(res.success){
            const data = res.data;
            return data;
        }else{
            throw new Error(res.msg as string)
        }
            
    }

    /**
     * Create new manual charge or Non-Ops Charge
     * @param request 
     * @returns 
     */
    const saveNewManualChargeDetail = async (request: ManualChargeDetailRequestEntity) : Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${saveDtlUrl}`, request) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }

    /**
     * Update the manual charge or Non-Ops Charge
     * @param request 
     * @returns 
     */
    const replaceOldManualChargeDetail = async (request: ManualChargeDetailRequestEntity) : Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${replaceDtlUrl}`, request) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }

    /**
     * Apply the manual charge or Non-Ops Charge
     * @param request 
     * @returns 
     */
    const applyManualChargeDetail = async (request: ManualChargeDetailApplyRequestEntity) : Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${applyUrl}`, request) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }
    

    /**
     * Delete the manual charge(s) by detail id(s)
     * @param dtlIds 
     * @returns 
     */
    const deleteManualChargeDetails = async (dtlIds: number[]) : Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${deleteUrl}`, dtlIds) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }

    /**
     * Get the attachment message for manual charge
     * @param manChgDtlId 
     * @returns 
     */
    const getManualChargeDetailAttachmentMessage = async (manChgDtlId: number) : Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosGetData(chargeAxiosInstance, `${getDtlAttMsgUrl}`, [manChgDtlId.toString()]) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
        return res;
    }

    /**
     * Save manual charge or non ops charge
     * @param request 
     * @returns 
     */
    const saveAll = async (request: ManualChargeDetailApplyRequestEntity) : Promise<ResponseEntity> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${saveAllUrl}`, request) as ResponseEntity;
        } catch (error) {
            throw error;
        }
        return res;
    }
    
    const adjustmentManualChargeDetails = async(adjEntity:ManualChargeDetailAdjustmentRequestEntity) : Promise<String> => {
        return await axiosPostData(chargeAxiosInstance, `${adjustmentUrl}`, adjEntity).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Adjustment Manual Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
       
    }

    const getManualChargeDetailCntr = async(request: ManualChargeDetailCntrRequestEntity) : Promise<ManualChargeDetailCntrEntity[]> => {
        return await axiosPostData(chargeAxiosInstance, `/v1/manualChargeDetail/cntrDetail/search`, request).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Get Manual Charge Detail Cntr Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const initManualChargeDetailCntr = async(request: ManualChargeDetailCntrRequestEntity) : Promise<ManualChargeDetailCntrEntity[]> => {
        return await axiosPostData(chargeAxiosInstance, `/v1/manualChargeDetail/cntrDetail/initialSearch`, request).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Init Manual Charge Detail Cntr Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const applyManualChargeDetailCntr = async(request: ManualChargeDetailCntrRequestEntity) : Promise<ManualChargeDetailEntity> => {
        return await axiosPostData(chargeAxiosInstance, `/v1/manualChargeDetail/cntrDetail/apply`, request).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Init Manual Charge Detail Cntr Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const uploadAttachment = async(manualChgDtl: ManualChargeDetailEntity) : Promise<string> => {
        return await axiosPostData(chargeAxiosInstance, `/v1/manualChargeDetail/cntrDetail/uploadAttachment`, manualChgDtl).then(res => {
            if (!res.success) {
                let errorMessage = "Init Manual Charge Detail Cntr Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;                
            } else {
                return res.data;
            }           
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    return {
        getManualChargeDetailByHdrId: getManualChargeDetailByHdrId,    
        saveNewManualChargeDetail: saveNewManualChargeDetail,  
        replaceOldManualChargeDetail: replaceOldManualChargeDetail,
        applyManualChargeDetail: applyManualChargeDetail,
        deleteManualChargeDetails: deleteManualChargeDetails,
        getManualChargeDetailAttachmentMessage: getManualChargeDetailAttachmentMessage,
        saveAll: saveAll,
        adjustmentManualChargeDetails: adjustmentManualChargeDetails,
        getManualChargeDetailCntr: getManualChargeDetailCntr,
        initManualChargeDetailCntr: initManualChargeDetailCntr,
        applyManualChargeDetailCntr: applyManualChargeDetailCntr,
        uploadAttachment: uploadAttachment
    }
}