export const AdjustmentTypePolicy = ({
    ADJ_TYPE_CANCEL: "CANCEL",
    ADJ_TYPE_CANCEL_REBILL: "CANCEL_REBILL",
    ADJ_TYPE_ADJUSTMENT: "ADJUSTMENT",
    ADJ_TYPE_NORMAL: "NORMAL",
    ADJ_TYPE_RECAL: "RECAL",
    ADJ_TYPE_CANCEL_REBILL_TO_OTHER_COMPANY: "CANCEL_REBILL_TO_OTHER_COMPANY",

    TEXT_CANCEL: "Cancel Only",
    TEXT_CANCEL_REBILL: "Cancel & Re-bill",
    TEXT_ADJUSTMENT: "Adjustment",
    TEXT_NORMAL: "Normal",
    TEXT_RECAL: "Recal",
    TEXT_CANCEL_REBILL_TO_OTHER_COMPANY: "Cancel & Re-bill to other company",
})