
export interface ManualChargeDetailAdjustmentRequestEntity {
    manualDtlIds?: Object[] | null,
	reasonCode?: string | null,
	adjustmentType?: string | null,
}

export const EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY:ManualChargeDetailAdjustmentRequestEntity = {
	manualDtlIds: [],
	reasonCode: null,
	adjustmentType: null,
}