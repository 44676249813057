
import { ShiftEntity } from "domain/entity/Shift/ShifEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ShiftRepository } from "./ShiftRepo";

export const ShiftRepoImpl = (): ShiftRepository => {
    const url = '/v1/shift';
    const searchByOpsDateUrl = '/v1/searchShiftCodeMappingByOpsDate';

    const getAllShifts = async() : Promise<ShiftEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }

    const getShiftByKey = async(key: string): Promise<ShiftEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getShiftByType = async(type: string): Promise<ShiftEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}/shiftType/${type}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewShift = async(newData: ShiftEntity) : Promise<ShiftEntity> => {
        const result = await axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Shift Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Shift ${newData.shiftCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateShift = async(updatedData: ShiftEntity): Promise<ShiftEntity> => {
        const result = await axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteShiftByKey = async(key: string): Promise<boolean> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getShiftCodeMappingByOpsDate = async(request: any): Promise<ShiftEntity> => {
        const result = await axiosPostData(referenceAxiosInstance, `${searchByOpsDateUrl}`, request).then(res => {
            const data = res.data;
            return data;
        })
        return result;
    }

    return {
        getAllShifts: getAllShifts,
        getShiftByKey: getShiftByKey,
        getShiftByType: getShiftByType,
        createNewShift: createNewShift,
        updateShift: updateShift,
        deleteShiftByKey: deleteShiftByKey,
        getShiftCodeMappingByOpsDate: getShiftCodeMappingByOpsDate,
    }
}