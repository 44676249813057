import { CompanyCustomerEntity } from "domain/entity/Company/CompanyCustomerEntity";
import { CustomerEntity } from "domain/entity/Company/CustomerEntity";
import { CustomerSearchCriteria } from "domain/entity/Company/CustomerSearchCriteria";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CustomerRepository } from "./CustomerRepo";

export const CustomerRepoImpl = (): CustomerRepository => {
    const url = '/v1/customer';

    const getAllCustomers = async() : Promise<CustomerEntity[]> => {
        return axiosGetData(companyAxiosInstance, url, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const getCustomerByKey = async(key: string): Promise<CustomerEntity> => {
        return axiosGetData(companyAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getCustomerByCode = async(code: string): Promise<CustomerEntity> => {
        return axiosGetData(companyAxiosInstance, `${url}/code/${code}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getCustomerActive = async(): Promise<CustomerEntity[]> => {
        return axiosGetData(companyAxiosInstance, `${url}/active`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }


    const createNewCustomer = async(newData: CustomerEntity) : Promise<CustomerEntity> => {
        const result = axiosPostData(companyAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Customer Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Customer ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateCustomer = async(updatedData: CustomerEntity): Promise<CustomerEntity> => {
        const result = axiosPutData(companyAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteCustomerByKey = async(key: string): Promise<boolean> => {
        return axiosDeleteData(companyAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getCustomersByCompany = async(customerSearchCriteria: CustomerSearchCriteria): Promise<CompanyCustomerEntity[]> => {
        const newUrl = '/v1/getCustomersByCompany';
        return axiosPostData(companyAxiosInstance, `${newUrl}`, customerSearchCriteria).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getAllCustomers: getAllCustomers,
        getCustomerByKey: getCustomerByKey,
        getCustomerByCode: getCustomerByCode,
        getCustomerActive: getCustomerActive,
        createNewCustomer: createNewCustomer,
        updateCustomer: updateCustomer,
        deleteCustomerByKey: deleteCustomerByKey,
        getCustomersByCompany: getCustomersByCompany
    }
}