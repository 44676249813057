import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { StandardTariffCodeRepository } from "./StandardTariffCodeRepo";

export const StandardTariffCodeRepoImpl = (): StandardTariffCodeRepository => {
    const url = '/v1/standardTariffCodeForComboBox';
    const tariffByTypeCodeUrl = '/v1/standardTariffCodeByTypeAndCode';

    const getAllStandardTariffCodes = async() : Promise<StandardTariffCodeEntity[]> => {
        return axiosGetData(tariffAxiosInstance, url, []).then(res => {
            return _.sortBy(res.data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getStandardTariffCodeByKey = async(key: string): Promise<StandardTariffCodeEntity> => {
        return axiosGetData(tariffAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createNewStandardTariffCode = async(newData: StandardTariffCodeEntity) : Promise<StandardTariffCodeEntity> => {
        const result = axiosPostData(tariffAxiosInstance, `${url}`, newData).then(res => {
            return res.data;
        }).catch(err => {
            let errorMessage = "Update Standard Tariff Code Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Standard Tariff Type ${newData.tariffType} Tariff Code ${newData.tariffCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateStandardTariffCode = async(updatedData: StandardTariffCodeEntity): Promise<StandardTariffCodeEntity> => {
        const result = axiosPutData(tariffAxiosInstance, `${url}`, updatedData).then(res => {
            return res.data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteStandardTariffCodeByKey = async(key: string): Promise<boolean> => {
        return axiosDeleteData(tariffAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const getTariffByTypeAndCode = async(tariffType: string,tariffCode: string): Promise<StandardTariffCodeEntity> => {
        const bo:StandardTariffCodeEntity = {
            ...EMPTY_STANDARD_TARIFF_CODE_ENTITY,
            tariffCode: tariffCode,
            tariffType: tariffType
        }
        return axiosPostData(tariffAxiosInstance, `${tariffByTypeCodeUrl}`, bo).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    return {
        getAllStandardTariffCodes: getAllStandardTariffCodes,
        getStandardTariffCodeByKey: getStandardTariffCodeByKey,
        createNewStandardTariffCode: createNewStandardTariffCode,
        updateStandardTariffCode: updateStandardTariffCode,
        deleteStandardTariffCodeByKey: deleteStandardTariffCodeByKey,
        getTariffByTypeAndCode: getTariffByTypeAndCode,
    }
}