import { MasterDataEntity } from "domain/entity/MasterData/MasterDataEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { MasterDataRepository } from "./MasterDataRepo";

export const MasterDataRepoImpl = () : MasterDataRepository => {
    const url = "/v1/masterdata/type"

    const getMasterDataByKey = async(key:string) : Promise<MasterDataEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, `${url}`, [key]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getMasterDataByKey: getMasterDataByKey
    }
}