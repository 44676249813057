import { CompanyCustomerInfoEntity } from "domain/entity/Company/CompanyCustomerInfoEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CompanyCustomerInfoRepository } from "./CompanyCustomerInfoRepo";

export const CompanyCustomerInfoRepoImpl = (): CompanyCustomerInfoRepository => {
    const url = '/v1/companyCustomerInfo';

    const getCompanyCustomerInfo = async(companyCode: string) : Promise<CompanyCustomerInfoEntity[]> => {
        return axiosGetData(companyAxiosInstance, `${url}/company`, [companyCode]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }


    return {
        getCompanyCustomerInfo: getCompanyCustomerInfo,
    }
}