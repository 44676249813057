export interface IValidation {
  ValidateForm: (valueObject: { [x: string]: any }, successCallback: () => void) => Promise<any>;
  ValidateFormOnly: (valueObject: { [x: string]: any }) => Promise<any>;
  ValidateField: (fieldKey: string, fieldValue: any) => Promise<any>;
}

export const Validation = (validationSchema: any): IValidation => {
  async function validate(valueObject: any, successCallback?: () => void): Promise<any> {
    const isFormValid = await validationSchema.isValid(valueObject, {
      abortEarly: false,
    })

    if (isFormValid) {
      successCallback && successCallback();
      return null;
    } else {
      const res = await validationSchema.validate(valueObject, { abortEarly: false }).catch((err: any) => {
        const errs = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.message,
          }
        }, null);
        
        return errs;
      });
      return res;
    }
  }
  
  const ValidateForm = async (valueObject: any, successCallback: () => void) : Promise<any> => {
    const result = await validate(valueObject, successCallback).then((res) => {
      if (res) {
        return res;
      }
    }).catch((error) => {
      return null;
    })
    return result;
  };

  const ValidateFormOnly = async (valueObject: any) : Promise<any> => {
    const result = await validate(valueObject).then((res) => {
      if (res) {
        return res;
      }
    }).catch((error) => {
      return null;
    })
    return result;
  };

  const ValidateField = async (fieldKey: string, fieldValue: any) : Promise<any> => {
    let errors:{[key:string]:string} = {};
    if (validationSchema.fields[fieldKey]) {
      validationSchema.validateAt(fieldKey, fieldValue).catch((err: any) => {
          if (err.inner.length > 0) {
            errors[err.path] = err.message;
          } else {
            delete errors[err.path];
          }

          return errors;
      });      
    }
    return errors;
  };

  return {
    ValidateForm,
    ValidateFormOnly,
    ValidateField
  }
}