import { EMPTY_TARIFF_COA_ENTITY, TariffCoaMappingEntity } from "domain/entity/Document/TariffCoaMappingEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { TariffCoaMappingRepository } from "./TariffCoaMappingRepo";
export const TariffCoaMappingRepoImpl = (): TariffCoaMappingRepository => {
    const url = '/v1/tariffcoa';

    const getAllTariffCoas = async() : Promise<TariffCoaMappingEntity[]> => {
        return axiosGetData(companyAxiosInstance, url, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const getTariffCoaByKey = async(key: string): Promise<TariffCoaMappingEntity> => {
        return axiosGetData(companyAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }   

    const getTariffCoaByTarTypeCode = async(tariffType: string, tariffCode: string) : Promise<TariffCoaMappingEntity> => {
        const newData: TariffCoaMappingEntity = {
            ...EMPTY_TARIFF_COA_ENTITY,
            tariffCode: tariffCode,
            tariffType: tariffType
        }
        return axiosPostData(companyAxiosInstance, url, newData).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const createNewTariffCoa = async(newData: TariffCoaMappingEntity) : Promise<TariffCoaMappingEntity> => {
        const result = axiosPostData(companyAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Tariff Coa Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Tariff Coa ${newData.tariffType}/${newData.tariffCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateTariffCoa = async(updatedData: TariffCoaMappingEntity): Promise<TariffCoaMappingEntity> => {
        const result = axiosPutData(companyAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteTariffCoaByKey = async(key: string): Promise<boolean> => {
        return axiosDeleteData(companyAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllTariffCoas: getAllTariffCoas,
        getTariffCoaByKey: getTariffCoaByKey,
        getTariffCoaByTarTypeCode: getTariffCoaByTarTypeCode,
        createNewTariffCoa: createNewTariffCoa,
        updateTariffCoa: updateTariffCoa,
        deleteTariffCoaByKey: deleteTariffCoaByKey
    }
}