import { DECIMAL_2, DECIMAL_4 } from "presentation/utils/numberUtil";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { NonOpsConstant } from "./NonOpsConstant";

const NON_OPS_HDR_CONSTANT = NonOpsConstant.Header;
const NON_OPS_DTL_CONSTANT = NonOpsConstant.Detail;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let numberFieldList:string[] = [];

export const INITIAL_NON_OPS_DTL_COL_DEF: any[] = [
    {
        headerName: NON_OPS_HDR_CONSTANT.STATE,
        field: 'dtlState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.DTL_DESC1,
        field: 'dtlDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.DTL_DESC2,
        field: 'dtlDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.AMT,
        field: 'chargeAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.UNIT_PRICE,
        field: 'individualChargeAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_4,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.CHARGE_QTY,
        field: 'chargeQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.UOM,
        field: 'individualChargeQtyUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAR_TYPE,
        field: 'vatTarType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_TAR_CODE,
        field: 'vatTarCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.REV_AC_CODE,
        field: 'revenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.COST_CENTER_CODE,
        field: 'costCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.PROD_SER_CODE,
        field: 'productServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.SALES_CHANNEL_CODE,
        field: 'salesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.COUNTRY_CODE,
        field: 'countryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.PROJECT_CODE,
        field: 'projectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.SPARE_CODE,
        field: 'spareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.COMPANY_CODE,
        field: 'revenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.INTER_COMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_CODE,
        field: 'vatOneOffTaxCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    }, 
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_PAYMENT_TERM,
        field: 'vatDays',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_PAYMENT_REF_TERM,
        field: 'paymentReferenceDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 350,
    },

    {
        headerName: NON_OPS_DTL_CONSTANT.TX_TYPE,
        field: 'vatTransactionType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.ARTICLE_STATEMENT_CODE,
        field: 'vatArticleStatementCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.ARTICLE_STATEMENT,
        field: 'vatArticleStatement',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_PERCENTAGE,
        field: 'vatPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },

    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_REV_AC_CODE,
        field: 'taxRevenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_COST_CENTER_CODE,
        field: 'taxCostCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_PROD_SER_CODE,
        field: 'taxProductServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_SALES_CHANNEL_CODE,
        field: 'taxSalesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_COUNTRY_CODE,
        field: 'taxCountryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_PROJECT_CODE,
        field: 'taxProjectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_SPARE_CODE,
        field: 'taxSpareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_COMPANY_CODE,
        field: 'taxRevenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_INTER_COMPANY_CODE,
        field: 'taxIntercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },

    
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_DTL_DESC1,
        field: 'vatDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.TAX_DTL_DESC2,
        field: 'vatDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.REF_NO_ADJ_FROM,
        field: 'adjRefNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.INV_NO,
        field: 'invoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.DRAFT_INV_NO,
        field: 'draftInvoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_HDR_CONSTANT.DRAFT_CREDIT_NOTE_NO,
        field: 'draftCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.REASON_CODE,
        field: 'reasonCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: NON_OPS_DTL_CONSTANT.EX_MSG,
        field: 'exceptionMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['dtlState'] = calculateStateColor;
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}