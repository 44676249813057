export const ParameterCodeValue = {
    DEFAULT_TEU_FACTOR_CODE:"DEFAULT_TEU_FACTOR",
    BILLING_CNTR_SIZE:"BILLING_CNTR_SIZE",
    MARSHALLING_TYPE:"MARSHALLING_TYPE",    
    EXCL_MARSHALLING_CHARGE:"EXCL_MARSHALLING_CHARGE",
    CNTR_SIZE:"CNTR_SIZE",
    CNTR_TYPE:"CNTR_TYPE",
    PRINTER:"PRINTER",
    CARGO_TYPE:"CARGO_TYPE",
    CARGO_CODE:"CARGO_CODE",
    ACCOUNT_TERMINAL_REASON_CODE:"ACCT_TML_REASON_CODE",
    TAX_CODE:"AR_TAX_CODE",
    GL_DEFAULT:"AR_DEFAULT_SETTING",
    GL_COUNTRY:"AR_COUNTRY",
    RA_TYPE:"RA_TYPE",
    REQ_NATURE:"REQ_NATURE",
    ARTICLE_STMT_CODE:"ARTICLE_STMT_CODE",
    REQ_MGR_IN_CHARGE:"REQ_MGR_IN_CHARGE",
    REQ_ACCT_MGR:"REQ_ACCT_MGR",
    REQ_FIN:"REQ_FIN",
    REQ_COM:"REQ_COM",
    REQ_MD:"REQ_MD",
    REQ_GMD:"REQ_GMD",
    LOCAL_CURRENCY:"LOCAL_CURRENCY",
    POOL_TYPE:"POOL_TYPE",
    SHIPMENT_TYPE:"SHIPMENT_TYPE",
    FSP_COUNTING_FROM:"FSP_COUNTING_FROM",
    FSP_COUNTING_TO:"FSP_COUNTING_TO",
    FSP_COUNTING_DIRECTION:"FSP_COUNTING_DIRECTION",
    UOM:"UOM",
    DELAY_REASON_CODE:"DELAY_REASON_CODE",
    MOVEMENT_IND:"MOVEMENT_IND",
    GL_CUTOFF_DATE:"GL_CUTOFF_DATE",
    INV_CUTOFF_DATE:"INV_CUTOFF_DATE",
    HALF_DAY_CUTOFF_TIME:"HALF_DAY_CUTOFF_TIME",
    DOC_BILL_MSG:"DOC_BILL_MSG",
    DOC_PORT_CODE:"DOC_PORT_CODE",
    DOC_CN_BILL_MSG:"DOC_CN_BILL_MSG",
    DOC_ABN_CODE:"DOC_ABN_CODE",
    SP_HANDLING_CODE:"SP_HANDLING_CODE",
    LOCATION_TYPE:"LOCATION_TYPE",
    GL_CUTOFF_DAYS_AHEAD_MONTHEND:"GL_CUTOFF_DAYS_AHEAD_MONTHEND",
    GL_CUTOFF_SETTING:"GL_CUTOFF_SETTING",
    RAILWAY_SETTING:"RAILWAY_SETTING",
    KM_LESS_THAN_TO_CHARGE_PER_CNTR:"KM_LESS_THAN_TO_CHARGE_PER_CNTR",
    HANDLE_SHORTSEA_AS:"HANDLE_SHORTSEA_AS",
    SPECIAL_AREAS_TO_CHARGE_PER_CNTR:"SPECIAL_AREAS_TO_CHARGE_PER_CNTR",
    FORWARDER_REFERENCE:"FORWARDER_REFERENCE",
    EQUIPMENT_CODE:"EQUIPMENT_CODE",
    STORAGE_TAX_TIER_GROUP:"STORAGE_TAX_TIER_GROUP",
    REGION:"REGION",
    DOC_SIGNATURE_PRIORITY:"DOC_SIGNATURE_PRIORITY",
    BILLING_CYCLE:"BILLING_CYCLE",
    DAMAGE_TYPE:"DAMAGE_TYPE",
    SERVICE_ORDER_CODE:"SERVICE_ORDER_CODE",
    DEFAULT_INTERCOMPANY_CODE:"DEFAULT_INTERCOMPANY_CODE",
    DEFAULT_FSP_COMPANY:"DEFAULT_FSP_COMPANY",
    DEFAULT_OPERATING_TML:"DEFAULT_OPERATING_TML",
    HANDLING_CHARGE:"HANDLING_CHARGE",
    DEPOSIT_BANK_CODE:"DEPOSIT_BANK_CODE",
    REFUND_REJECT_REASON_CODE:"REFUND_REJECT_REASON_CODE",
    LET_PASS_AC_THRESHOLD:"LET_PASS_AC_THRESHOLD",
    GATE_PASS_GRACE_HOUR:"GATE_PASS_GRACE_HOUR",
    CREDIT_NOTE_NATURE:"CREDIT_NOTE_NATURE",
    ADHOC_HOLD_REASON:"ADHOC_HOLD_REASON",
    BILLING_TYPE_INBOUND_NON_CY:"BILLING_TYPE_INBOUND_NON_CY",
    BILLING_TYPE_OUTBOUND_NON_CY:"BILLING_TYPE_OUTBOUND_NON_CY",
    E_INVOICE_DEFAULT_SETTING:"E_INVOICE_DEFAULT_SETTING",
    SIM_REPORT_RECEIPIENTS:"SIM_REPORT_RECEIPIENTS",
    GL_BOOKS_ID:"GL_BOOKS_ID",
    GL_CREATED_BY:"GL_CREATED_BY",
    GL_DEFAULT_SETTING:"GL_DEFAULT_SETTING",
    AR_BOOKS_ID:"AR_BOOKS_ID",
    AR_CREATED_BY:"AR_CREATED_BY",
    AR_ORG_ID:"AR_ORG_ID",
    AR_DEFAULT_SETTING:"AR_DEFAULT_SETTING",
    TML_CODE:"TML_CODE",
    CHARGEABLE_INTRA_MOVE:"CHARGEABLE_INTRA_MOVE",
    DEFAULT_ESTIMATION_GL_COA:"DEFAULT_ESTIMATION_GL_COA",
    DEFAULT_RESERVATION_GL_COA:"DEFAULT_RESERVATION_GL_COA",
    OPERATION_TYPE:"OPERATION_TYPE",    
    CHANGE_KEY_DATA_HANDLING_STATUS:"CHANGE_KEY_DATA_HANDLING_STATUS",        
    HANDLING_TERMINAL:"HANDLING_TERMINAL",    
    DIVERT_SHIP_FROM:"DIVERT_SHIP_FROM",    
    EXCL_UPD_BILLING_CYCLE_DOMESTIC_CHG:"EXCL_UPD_BILLING_CYCLE_DOMESTIC_CHG",    
    DG_IMCO_CODE_DISPLAY_SUPPORTING:"DG_IMCO_CODE_DISPLAY_SUPPORTING",    
    EXCL_AUTO_COKD_COMPANY:"EXCL_AUTO_COKD_COMPANY",
    INVOICE_ITEM_GROUPING:"INVOICE_ITEM_GROUPING",        
    DEFAULT_AR_TAX_CODE:"DEFAULT_AR_TAX_CODE",    
    DAILY_REPORT_EMAIL_INFO:"DAILY_REPORT_EMAIL_INFO",    
    MEC_OTO_FULL_CALCULATION:"MEC_OTO_FULL_CALCULATION",    
    SORTING_SEQ_SEARCH:"SORTING_SEQ_SEARCH",    
    CHARGE_MAINT_HANDLING_STATUS:"CHARGE_MAINT_HANDLING_STATUS",
    APPLY_COKD_AFTER_DEPARTURE:"APPLY_COKD_AFTER_DEPARTURE",    
    INV_AMT_NO:"INV_AMT_NO",
    INV_AMT_MGS:"INV_AMT_MGS",
    INV_CRED_AMT_CN:"INV_CRED_AMT_CN",
}

export const ParameterDetailCodeValue = {
    TAX_PERCENTAGE:'TAX_PERCENTAGE',
    TAX_TAR_TYPE:'TAX_TAR_TYPE',
    TAX_TAR_CODE:'TAX_TAR_CODE',
    ARTICLE_STATEMENT:'ARTICLE_STATEMENT',
    TAX_PAYMENT_TERM:'TAX_PAYMENT_TERM',
    TAX_PAYMENT_REFERENCE_DATE:'TAX_PAYMENT_REFERENCE_DATE',
    TAX_DETAIL_DESC:'TAX_DETAIL_DESC',

    MANUAL_CHARGE_DEF_TAX_CODE:'MANUAL_CHARGE_DEF_TAX_CODE',
    CREDIT_NOTE_DEF_TAX_CODE:'CREDIT_NOTE_DEF_TAX_CODE',
    NON_OPS_DEF_TAX_CODE:'NON_OPS_DEF_TAX_CODE',
    DAILY_INVOICE_SUMMARY:'DAILY_INVOICE_SUMMARY',
    OUTSTANDING_TURNOVER_BY_CLERK:'OUTSTANDING_TURNOVER_BY_CLERK',
    OUTSTANDING_TURNOVER_SUMMARY:'OUTSTANDING_TURNOVER_SUMMARY',
    DAILY_REPORT_EMAIL_CONTENT:'DAILY_REPORT_EMAIL_CONTENT',
    DAILY_REPORT_EMAIL_SUBJECT:'DAILY_REPORT_EMAIL_SUBJECT',
    DAILY_REPORT_EMAIL_ADDRESS:'DAILY_REPORT_EMAIL_ADDRESS',
    DAILY_REPORT_EMAIL_ADDRESS_CC:'DAILY_REPORT_EMAIL_ADDRESS_CC',

    MARSHALLING_TYPE:'MARSHALLING_TYPE',
    MARSHALLING_FROM_TO:'MARSHALLING_FROM_TO',
    CHARGE_DATA_ENQUIRY: 'CHARGE_DATA_ENQUIRY'
}