import { DataMappingEntity } from "../Common/DataMappingEntity"

export interface TariffCoaMappingEntity extends DataMappingEntity {
    id: number | null,
    tariffType: string | null,
    tariffCode: string | null,
    raType: string | null,
    revenueCompanyCode: string | null,
    revenueAccountCode: string | null,
    costCenterCode: string | null,
    productServiceCode: string | null,
    salesChannelCode: string | null,
    projectCode: string | null,
    spareCode: string | null,

    [key: string]: string | number | Date | null | undefined 
}

export const EMPTY_TARIFF_COA_ENTITY : TariffCoaMappingEntity = {
    id: null,
    tariffType: null,
    tariffCode: null,
    raType: null,
    revenueCompanyCode: null,
    revenueAccountCode: null,
    costCenterCode: null,
    productServiceCode: null,
    salesChannelCode: null,
    projectCode: null,
    spareCode: null,
    priority: 0,
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    activeInd: 'Y',
    mappingType: "TariffCoaMappingEntity"
}