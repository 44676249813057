import { CompanyEntity } from "domain/entity/Company/CompanyEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CompanyRepository } from "./CompanyRepo";

export const CompanyRepoImpl = (): CompanyRepository => {
    const url = '/v1/company';

    const getAllCompanyForCombobox = async() : Promise<CompanyEntity[]> => {
        return axiosGetData(companyAxiosInstance, `/v1/getAllCompanyForCombobox`, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getAllCompanies = async() : Promise<CompanyEntity[]> => {
        return axiosGetData(companyAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getCompanyByKey = async(key: string): Promise<CompanyEntity> => {
        return axiosGetData(companyAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewCompany = async(newData: CompanyEntity) : Promise<CompanyEntity> => {
        const result = axiosPostData(companyAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateCompany = async(updatedData: CompanyEntity): Promise<CompanyEntity> => {
        const result = axiosPutData(companyAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteCompanyByKey = async(key: string): Promise<boolean> => {
        return axiosDeleteData(companyAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllCompanyForCombobox: getAllCompanyForCombobox,
        getAllCompanies: getAllCompanies,
        getCompanyByKey: getCompanyByKey,
        createNewCompany: createNewCompany,
        updateCompany: updateCompany,
        deleteCompanyByKey: deleteCompanyByKey
    }
}