import { axiosPostData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffProposalRepository } from "./TariffProposalRepo";

export const TariffProposalRepoImpl = (): TariffProposalRepository => {
    const searchDefaultQtyUomUrl = '/v1/searchDefaultQtyUomByTariff';

    const searchDefaultQtyUomByTariff = async(request:any) : Promise<string> => {
        const result = axiosPostData(tariffAxiosInstance, `${searchDefaultQtyUomUrl}`, request).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Get qty UOM by tariff Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.message; 
        });
        return result;
    }


    return {
        searchDefaultQtyUomByTariff: searchDefaultQtyUomByTariff,
    }
}