export interface StandardTariffCodeEntity {
    key: string,
    versionIdentifier: {
        version?: string
    },
    tariffType: string,
    tariffCode: string,
    creationType: "StandardTariffCodeEntity",
    seq: number | null,
    parentTariffType?: string | null,
    parentTariffCode?: string | null,
    tariffCodeDesc?: string | null,
    tariffCodeDescOther?: string | null,
    includeTputInd?: string | null,
    percentage?: number | null,
    tariffSchemeId?: number | null,
    activeInd: "Y" | "N",
    tariffGroupId1: string | null,
    tariffGroupId2: string | null,
    tariffGroupId3: string | null,
    tariffSeq1: number | null,
    tariffSeq2: number | null,
    tariffSeq3: number | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_STANDARD_TARIFF_CODE_ENTITY : StandardTariffCodeEntity = {
    key: "",
    versionIdentifier: {},
    tariffType: "",
    tariffCode: "",
    creationType: "StandardTariffCodeEntity",
    seq: null,
    activeInd: "Y",
    tariffGroupId1: null,
    tariffGroupId2: null,
    tariffGroupId3: null,
    tariffSeq1: null,
    tariffSeq2: null,
    tariffSeq3: null,
}