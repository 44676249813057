import { EMPTY_NON_OPS_HEADER_MODEL } from 'presentation/model/NonOpsMaintenance/NonOpsHeaderModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: NonOpsHeaderProvider,
    useTracked: useNonOpsHeaderTracked
} = createContainer(() => useState(EMPTY_NON_OPS_HEADER_MODEL), {concurrentMode: true});
export { NonOpsHeaderProvider, useNonOpsHeaderTracked };

