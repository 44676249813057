import { CoaEntity } from "domain/entity/Company/CoaEntity";
import { CoaSearchCriteria } from "domain/entity/Company/CoaSearchCriteria";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CoaRepository } from "./CoaRepo";

export const CoaRepoImpl = (): CoaRepository => {
    const url = '/v1/coa';
    const coaSearchUrl = '/v1/coaSearch';

    const getAllCoas = async() : Promise<CoaEntity[]> => {
        return axiosGetData(companyAxiosInstance, url, []).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    const getCoaByKey = async(key: string): Promise<CoaEntity> => {
        return axiosGetData(companyAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewCoa = async(newData: CoaEntity) : Promise<CoaEntity> => {
        const result = axiosPostData(companyAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Coa Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Coa ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateCoa = async(updatedData: CoaEntity): Promise<CoaEntity> => {
        const result = axiosPutData(companyAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteCoaByKey = async(key: string): Promise<boolean> => {
        return axiosDeleteData(companyAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const searchCoas = async(request: CoaSearchCriteria) : Promise<CoaEntity[]> => {
        return axiosPostData(companyAxiosInstance, coaSearchUrl, request).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getAllCoas: getAllCoas,
        getCoaByKey: getCoaByKey,
        createNewCoa: createNewCoa,
        updateCoa: updateCoa,
        deleteCoaByKey: deleteCoaByKey,
        searchCoas: searchCoas,
    }
}