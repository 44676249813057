import { ChargeConstantCollection } from 'constants/charge/ChargeConstantCollection';
import { DocumentConst } from 'constants/document/DocumentConst';
import moment from 'moment';
import * as yup from 'yup';
import { MessageConstant, formatMessage } from '../MessageConstant';
import { NonOpsConstant } from './NonOpsConstant';

const vatPaymentTerms = Object.values(DocumentConst.vatPaymentRefDateType);
const uoms = Object.values(ChargeConstantCollection.uom);
const userMessage = MessageConstant.common;

const HDR_CONST = NonOpsConstant.Header;

function ValidateDateRange(fromKey:string,toKey:string, context: yup.TestContext<yup.AnyObject>) {
  const fromDate = context.parent[fromKey];
  const toDate = context.parent[toKey];
  if(!toDate){
    return true;
  }
  const varFromDate = moment(fromDate);
  const varToDate = moment(toDate);

  let error: string = '';
  if (varFromDate > varToDate) {
    error = formatMessage(userMessage.TO_GRATER_FROM, 
      {"{fromDate}":HDR_CONST.EFF_DATE,
       "{toDate}":HDR_CONST.EXP_DATE})
  }

  if (error === '') {
    return true;
  } else {
    return false;
  }
}

// function validateAmountQty(context: yup.TestContext<yup.AnyObject>) {
//   const chargeAmount = context.parent['chargeAmount'];
//   const chargeQty = context.parent['chargeQty'];
//   const individualChargeAmount = context.parent['individualChargeAmount'];

//   let error: string = '';
//   if (!chargeAmount && !(chargeQty || individualChargeAmount)) {
//     error = "Please input Total Amount or Quantity & Price!"
//   }else{
//     let amount = 0;
//     if(chargeAmount){
//       amount = chargeAmount
//     }else{
//       amount = chargeQty * individualChargeAmount;
//     }
//     if(amount <= 0 ) {
//       error = "Credit Charging Qty should be greater than 0."
//     }
//   }


//   if (error === '') {
//     return true;
//   } else {
//     return false;
//   }
// }


export const commonNonOpsDtlValidation: { [x: string]: any; } = {
    individualChargeAmount: yup.number().nullable().transform((value) => (isNaN(value) ? undefined : value)).positive("Must be a positive number"),
    chargeAmount: yup.number().nullable().transform((value) => (isNaN(value) ? undefined : value)).positive("Must be a positive number"),
    chargeQty: yup.number().nullable().min(1).max(9999),
    
    // validateAmountQty: yup.number().test("amount-qty-check",
    //   'Please input Total Amount or Quantity & Price!',
    //   function(){
    //     return validateAmountQty(this);
    // }),
    tariffType: yup.string().required("Missing"),
    tariffCode: yup.string().required("Missing"),
    vatPercentage: yup.number().required("Missing").min(0).max(100),
    vatOneOffTaxCode: yup.string().required("Missing"),
    individualChargeQtyUom: yup.string().required("Missing").oneOf(uoms),
    vatTarType: yup.string().required("Missing"),
    vatTarCode: yup.string().required("Missing"),
    vatArticleStatementCode: yup.string().required("Missing"),
    vatDays: yup.number().required("Missing").positive("Must be a positive number"),
    paymentReferenceDate: yup.string().required("Missing").oneOf(vatPaymentTerms),
   
    dtlDesc1: yup.string().required("Missing"),
    revenueAccountCode: yup.string().required("Missing"),
    costCenterCode: yup.string().required("Missing"),
    productServiceCode: yup.string().required("Missing"),
    salesChannelCode: yup.string().required("Missing"),
    projectCode: yup.string().required("Missing"),
    spareCode: yup.string().required("Missing"),
    intercompanyCode: yup.string().required("Missing"),
    taxRevenueAccountCode: yup.string().required("Missing"),
    taxCostCenterCode: yup.string().required("Missing"),
    taxProductServiceCode: yup.string().required("Missing"),
    taxSalesChannelCode: yup.string().required("Missing"),
    taxProjectCode: yup.string().required("Missing"),
    taxSpareCode: yup.string().required("Missing"),
    taxIntercompanyCode: yup.string().required("Missing"),

};

export const commonNonOpsHdrValidation: { [x: string]: any; } = {
    billToCompany: yup.string().required("Missing"),
    customerCode: yup.string().required("Missing"),
    hdrDesc1: yup.string().required("Missing"),
    hdrDesc2: yup.string().required("Missing"),
    stdBillServiceDate: yup.date().required("Missing"),    
};

export const commonNonOpsHdrStdBillValidation: { [x: string]: any; } = {
  ...commonNonOpsHdrValidation,

  stdBillEffectiveDate: yup.date().required("Missing"),
  stdBillBillingCycle: yup.string().required("Missing"),  
  validateRangeDate: yup.string().test("date-range-check", 
    formatMessage(MessageConstant.common.TO_GRATER_FROM, 
      {"{fromDate}":HDR_CONST.EFF_DATE,
      "{toDate}":HDR_CONST.EXP_DATE
      }), 
    function(){
      return ValidateDateRange('stdBillEffectiveDate','stdBillExpiryDate',this);
    }),
    validateServEffDate: yup.string().test("date-range-check", 
      formatMessage(MessageConstant.common.TO_GRATER_FROM, 
        {"{fromDate}":HDR_CONST.EFF_DATE,
        "{toDate}":HDR_CONST.SERV_DATE
        }), 
      function(){
        return ValidateDateRange('stdBillEffectiveDate','stdBillServiceDate',this);
    }),

};


export const createNonOpsDtlValidation: { [x: string]: any; } = {
  ...commonNonOpsDtlValidation,
};

export const updateNonOpsDtlValidation: { [x: string]: any; } = {
  ...commonNonOpsDtlValidation,
};

export const createNonOpsHdrValidation: { [x: string]: any; } = {
  ...commonNonOpsHdrValidation,
};

export const updateNonOpsHdrValidation: { [x: string]: any; } = {
  ...commonNonOpsHdrValidation,
};

export const createNonOpsHdrStdBillValidation: { [x: string]: any; } = {
  ...commonNonOpsHdrStdBillValidation,
};

export const updateNonOpsHdrStdBillValidation: { [x: string]: any; } = {
  ...commonNonOpsHdrStdBillValidation,
};


export const createNonOpsHdrValidationSchema = yup.object().shape(createNonOpsHdrValidation);
export const updateNonOpsHdrValidationSchema = yup.object().shape(updateNonOpsHdrValidation);

export const createNonOpsHdrStdBillValidationSchema = yup.object().shape(createNonOpsHdrStdBillValidation);
export const updateNonOpsHdrStdBillValidationSchema = yup.object().shape(updateNonOpsHdrStdBillValidation);

export const createNonOpsDtlValidationSchema = yup.object().shape(createNonOpsDtlValidation);
export const updateNonOpsDtlValidationSchema = yup.object().shape(updateNonOpsDtlValidation);