import _ from "lodash";

export const formatMessage = (message:string, replaceKey:{[key:string]:string}) => {
    if (!message) {
        return "";
    }
    if (!replaceKey) {
        return message;
    }
    let newMessage = message;
   _.forEach(
        _.toPairs(replaceKey), ([key, value]) => (
            newMessage = newMessage.replace(key, value)
        )
    );
    return newMessage;
}

export const MessageConstant = {
    common: {
        SAVED_DATA_SUCCESSFUL: 'created successful.',
        DELETED_DATA_SUCCESSFUL: 'Delete successful.',
        UPDATED_DATA_SUCCESSFUL: 'Update successful.',
        FIELD_REQUIRED: '"{fieldName}" is required.',
        TO_GRATER_FROM: '"{toDate} must greater than {fromDate}.',
        PASSWORD_LENGTH_SMALL: "The password length must be greater than or equal to 8.",
        PASSWORD_FORMAT_ERROR: "The password needs to include characters and numbers.",
        PASSWORD_NO_SAME: "The passwords entered twice are inconsistent.",
        STRING_ARRAY_REQUIRED: "Strings array must contain at least one element.",        
        EMAIL_ADDRESS_ERROR: "Incorrect email address format.",
        SELECT_A_RECORD_AT_LEAST: "Please select a record.",
        MISSING: "Missing",
        CHANGEPASSWORDSUCCESSFUL: "Change Password Successful.",

        OPERATION_DONE_SUCCESSFUL: "{operationDone} successful.",
        OPERATION_DONE_Failure: "{operationDone} failure.",
        DELETE_ALERT_TITLE:"Delete the selected data cannot be undone.",
        DELETE_ALERT_MESSAGE:`Please confirm your choice, or click "Cancel" to return to the page.`,
    },
    charge: {
        ADJUSTMENT_SUCCESSFUL: "Adjustment successful.",
        ADJUSTMENT_NO_ALLOWS: "No allows adjustment",
        APPLY_SUCCESSFUL: "Update successful.",
        APPLYALL_SUCCESSFUL: "Update All successful.",
        CONFIRM_SUCCESSFUL: "Confirm successful.",
        CONFIRMALL_SUCCESSFUL: "Confirm All successful.",
        UNCONFIRM_SUCCESSFUL: "Un-confirm successful.",
        UNCONFIRMALL_SUCCESSFUL: "Un-confirm All successful.",
        REDIRECT_COMMON_ACCOUNT_SUCCESSFUL: "Re-direct Common Account successful.",
        REVERSE_COMMON_ACCOUNT_SUCCESSFUL: "Reverse Common Account successful.",
        ASSIGN_COMMON_ACCOUNT_SUCCESSFUL: "Assign Common Account successful.",
        ASSIGN_ONE_OFF_RATE_SUCCESSFUL: "Assign/Resume One-off Rate successful.",
        RELEASE_MASTER_HOLD_SUCCESSFUL: "Release Master Hold successful.",
        HOLD_UNHOLD_SUCCESSFUL: "Hold/Un-hold successful.",
        ACC_RESUME_TML_SUCCESSFUL: "Acc/Resume Tml successful.",

        SEL_ONE_CHANGE_KEY_DATA: "Please only select one change key container record.",
        SEL_ONE_CHANGE_KEY_DATA_APPLY: "Please only select one change key container record to apply.",
        SEL_ONE_CHARGE_DTL_DATA_APPLY: "Please select one charge detail record to apply at least.",
        //SEL_ONE_CHANGE_KEY_DATA_EDIT: "Please only select one change key container record to edit.",
        DIFFERENCE_SUB_CHARGE_TYPES: "The sub-charge type of the selected rows are different, cannot update status.",
        DELIVERY_RECORD_MISSING_BILLING_CYCLE_CODE: "The selected record should contain billing cycle code.",
        DELIVERY_RECORD_MISSING_CY_YH: "Cannot found YH/CY charge under the vessel.",
        CAN_NOT_DELETE_NORMAL: "Record(s) cannot be deleted, Adjustment type is 'Normal'.",
        WHEN_DELETE_STATE_I: "Record(s) cannot be deleted,detail state is 'I'.",
        CAN_NOT_DELETE_REBILL_CHG: "The selected rebilled charge is not in R/F/E state, it is not allowed to delete.",
        WHEN_DELETE_ADJTYPE_RECAL: "The Adjustment Type is RECAL, cannot delete.",
    }
}