import { ChargeTypeEntity } from "domain/entity/ChargeType/ChargeTypeEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ChargeTypeRepository } from "./ChargeTypeRepo";

export const ChargeTypeRepoImpl = (): ChargeTypeRepository => {
    const url = '/v1/chargeType';

    const getAllChargeTypesForCombobox = async() : Promise<ChargeTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, "/v1/chargeTypeSearchForComboBox", []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }

    const getAllChargeTypes = async() : Promise<ChargeTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        })
        
    }

    const getChargeTypeByKey = async(key: string): Promise<ChargeTypeEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getChargeTypeByRole = async(role: string): Promise<ChargeTypeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}/role/${role}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewChargeType = async(newData: ChargeTypeEntity) : Promise<ChargeTypeEntity> => {
        const result = await axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Charge Type Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Charge Type ${newData.chargeType} Sub Charge Type ${newData.subChargeType}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateChargeType = async(updatedData: ChargeTypeEntity): Promise<ChargeTypeEntity> => {
        const result = await axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteChargeTypeByKey = async(key: string): Promise<boolean> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllChargeTypesForCombobox: getAllChargeTypesForCombobox,
        getAllChargeTypes: getAllChargeTypes,
        getChargeTypeByKey: getChargeTypeByKey,
        getChargeTypeByRole: getChargeTypeByRole,
        createNewChargeType: createNewChargeType,
        updateChargeType: updateChargeType,
        deleteChargeTypeByKey: deleteChargeTypeByKey
    }
}