import { ActiveInd } from "constants/ActiveInd";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { DocumentConst } from "constants/document/DocumentConst";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";

const stateList: { [key:string] : string; } = ChargeConstantCollection.dtlSate;
export const stateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(stateList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))
];

const hdrStateList: { [key:string] : string; } = ChargeConstantCollection.hdrState;
export const hdrStateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(hdrStateList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))
];

export const manHdrStateList: { [key:string] : string; } = ChargeConstantCollection.manHdrState;
export const manHdrStateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(manHdrStateList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))
];


const billingTypeList : { [key:string] : string; } = ChargeConstantCollection.billingType;
export const billingTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(billingTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))
];

const manSysList : { [key:string] : string; } = ChargeConstantCollection.systemManualInd;
export const manSysDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
    _.toPairs(manSysList), ([key, value]) => (
        {dropdownLabel: value, tagLabel: value, value: value}
    )
    )??[], "dropdownLabel"))
];

const operationTypeList : { [key:string] : string; } = ChargeConstantCollection.operationType;
export const operationTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(operationTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const bundleIndList : { [key:string] : string; } = ChargeConstantCollection.bundleInd;
export const bundleIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(bundleIndList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const modalityList : { [key:string] : string; } = ChargeConstantCollection.modality;
export const modalityDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(modalityList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const fullEmptyList : { [key:string] : string; } = ChargeConstantCollection.emptyFullInd;
export const fullEmptyDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(fullEmptyList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const emptyIndList : { [key:string] : string; } = ChargeConstantCollection.emptyInd;
export const emptyIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(emptyIndList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const endVisitIndList : { [key:string] : string; } = ActiveInd;
export const endVisitIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(endVisitIndList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const vesselTypeList : { [key:string] : string; } = ChargeConstantCollection.vesselType;
export const vesselTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(vesselTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const adjTypeList : { [key:string] : string; } = ChargeConstantCollection.adjType;
export const adjTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(adjTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: key}
        )
    )??[], "dropdownLabel"))];

    const adjTypeListForSearch : { [key:string] : string; } = ChargeConstantCollection.adjTypeForSearch;
    export const adjTypeForSearchDropdownOption: DropdownProps[] = [
        ...(_.orderBy(_.map(
            _.toPairs(adjTypeListForSearch), ([key, value]) => (
                {dropdownLabel: value, tagLabel: value, value: value}
            )
        )??[], "dropdownLabel"))];

const cntrTspStatusList : { [key:string] : string; } = ChargeConstantCollection.cntrTspStatus;
export const cntrTspStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cntrTspStatusList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const rfTempUnitList : { [key:string] : string; } = ChargeConstantCollection.rfTempUnit;
export const rfTempUnitDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(rfTempUnitList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const cntrStatusList : { [key:string] : string; } = ChargeConstantCollection.cntrStatus;
export const statusDropdownOptions: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cntrStatusList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const ignoreCntrStatusValidList : { [key:string] : string; } = ChargeConstantCollection.ignoreCntrStatusValid;
export const ignoreCntrStatusValidDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ignoreCntrStatusValidList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const manualTsLegChargeIndList : { [key:string] : string; } = ChargeConstantCollection.manualTsLegChargeInd;
export const manualTsLegChargeIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(manualTsLegChargeIndList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];
    
const deliveryStatusList : { [key:string] : string; } = ChargeConstantCollection.deliveryStatus;
export const deliveryStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(deliveryStatusList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

//add company code DropdownOption for copy common account
const companyCodeList : { [key:string] : string; } = ChargeConstantCollection.companyCode;
export const companyCodeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(companyCodeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];
//add UOM DropdownOption for bulk edit
const uomList : { [key:string] : string; } = ChargeConstantCollection.uom;
export const uomDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(uomList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];
//add UOM DropdownOption for empty pool
const emptyPoolUomList : { [key:string] : string; } = ChargeConstantCollection.emptyPoolUom;
export const uomForEmpDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(emptyPoolUomList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];
//add COKD Type DropdownOption for change Key Data
const cokdTypeList : { [key:string] : string; } = ChargeConstantCollection.cokdType;
export const cokdTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cokdTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];
//add COKD Sub Type DropdownOption for change Key Data
const cokdSubTypeList : { [key:string] : string; } = ChargeConstantCollection.cokdSubType;
export const cokdSubTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(cokdSubTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const vatPaymentReferenceDateList : { [key:string] : string; } = DocumentConst.vatPaymentRefDateType;
export const vatPaymentReferenceDateDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(vatPaymentReferenceDateList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))];

const billingCycleList : { [key:string] : string; } = ChargeConstantCollection.billingCycle;
export const billingCycleDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(billingCycleList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))
];

const directDischargeIndList: {[key:string] : string;} = ChargeConstantCollection.directDischargeInd;
export const directDischargeIndDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(directDischargeIndList), ([key, value]) => (
            {dropdownLabel: key, tagLabel: key, value: value}
        )
    )??[], "dropdownLabel"))
];

const ediMonitoringStatusList: { [key:string] : string; } = DocumentConst.ediMonitoringStatus;
export const ediMonitoringStatusDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ediMonitoringStatusList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: key, value: value}
        )
    )??[], "dropdownLabel"))
];

const ediIssueTypeList: { [key:string] : string; } = DocumentConst.issueType;
export const ediIssueTypeDropdownOption: DropdownProps[] = [
    ...(_.orderBy(_.map(
        _.toPairs(ediIssueTypeList), ([key, value]) => (
            {dropdownLabel: value, tagLabel: value, value: value}
        )
    )??[], "dropdownLabel"))
];