
import { EMPTY_NON_OPS_DETAIL_MODEL } from 'presentation/model/NonOpsMaintenance/NonOpsDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: NonOpsDetailProvider,
    useTracked: useNonOpsDetailTracked
} = createContainer(() => useState(EMPTY_NON_OPS_DETAIL_MODEL), {concurrentMode: true});
export { NonOpsDetailProvider, useNonOpsDetailTracked };

