import {Children, FC, useMemo} from "react";

type Props = {
    children?: React.ReactNode
};

export const CriteriaItemContainer: FC<Props> = ({children}) => {
    const memoHasChildren = useMemo(() => Children.toArray(children).length > 0, [children])
    const memoChildren = useMemo(() =>
            children
        , [children])
    const memoResponse = useMemo(() =>
            memoHasChildren ? <div className='im-flex-row'>
                {memoChildren}
            </div> : null
        , [memoHasChildren, memoChildren])
    return (
        memoResponse
    )
}