export interface NonOpsSearchCriteria { 
    refNo?: string | null,
    stateList?: string[] | null,
    billingCycleList?: string[] | null,
    customerCodeList?: string[] | null,
    invCreditNoteNo?: string | null,
    stdBill?: string | null,
    orderNo?: string | null,
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_NON_OPS_SEARCH_CRITERIA : NonOpsSearchCriteria = {
    refNo: null,
    stateList: [],
    invCreditNoteNo: null,
    billingCycleList: [],
    customerCodeList: [],
    stdBill: null,
    orderNo: null,
    createdDateFrom: null,
    createdDateTo: null,
}