import { EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY, ManualChargeDetailAdjustmentRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailAdjustmentRequestEntity";
import { EMPTY_MANUAL_CHARGE_DETAIL_ENTITY, ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_ENTITY, ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { RefObject } from "react";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface NonOpsDetailViewChangeState extends BaseViewChangeSate {
    currentSelectedData: ManualChargeDetailEntity,    
    editingHeader: ManualChargeHeaderEntity,  
    isShowHeaderInfo: boolean,  
    isShowDetailInfo: boolean,    
    allowAutoRefresh: boolean,
    updatedRows: ManualChargeDetailEntity[],
    isAdjustmentRead: boolean,
}

export interface NonOpsDetailDropdownOptions {
    billToCompDropdownOptions: DropdownProps[],
    billingCycleDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    currencyDropdownOptions: DropdownProps[],
    interCmpCodeDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    taxCodeDropdownOptions: DropdownProps[],
    taxTariffTypeDropdownOptions: DropdownProps[],
    taxTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    qtyUomDropdownOptions: DropdownProps[],
    articleStatementCodeDropdownOptions: DropdownProps[],
    taxPaymentRefDateDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    forwardCodeDropdownOptions: DropdownProps[],
    emptyIndDropdownOptions: DropdownProps[],
    partnerCodeDropdownOptions: DropdownProps[],
    tsStatusDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
    shiftsDropdownOptions: DropdownProps[],
    allTariffCodeeDropdownOptions:DropdownProps[],    
    reasonCodeDropdownOptions: DropdownProps[],
}

export interface NonOpsDetailModel {
    isLoading: boolean,
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: NonOpsDetailDropdownOptions,
    currentManualChargeHeader: ManualChargeHeaderEntity,
    manualChargeDetails: ManualChargeDetailEntity[],
    selectedManualChargeDetailRows: ManualChargeDetailEntity[],
    viewState: NonOpsDetailViewChangeState,
    allFormState: {[key:string]:string},
    adjustmentEntity: ManualChargeDetailAdjustmentRequestEntity,
    isShowAdjustmentPanel?: boolean,
}

export const EMPTY_NON_OPS_DETAIL_MODEL: NonOpsDetailModel = {
    isLoading: false,
    searchCounter: 0,
    allFormState: {},
    adjustmentEntity: EMPTY_MANUAL_CHARGE_DETAIL_ADJUSTMENT_REQUEST_ENTITY,
    isShowAdjustmentPanel: false,
    dynamicOptions: {
        billToCompDropdownOptions: [],
        billingCycleDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        customerCodeDropdownOptions: [],
        interCmpCodeDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        currencyDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        taxCodeDropdownOptions: [],
        taxTariffTypeDropdownOptions: [],
        taxTariffCodeDropdownOptions: {},
        qtyUomDropdownOptions: [],
        articleStatementCodeDropdownOptions: [],
        taxPaymentRefDateDropdownOptions: [],
        chargeIndDropdownOptions: [],
        forwardCodeDropdownOptions: [],
        emptyIndDropdownOptions: [],
        partnerCodeDropdownOptions: [],
        tsStatusDropdownOptions: [],
        operationTypeDropdownOptions: [],
        shiftsDropdownOptions: [],
        allTariffCodeeDropdownOptions: [],
        reasonCodeDropdownOptions: []
    },
    currentManualChargeHeader: EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
    manualChargeDetails: [],
    selectedManualChargeDetailRows: [],
    viewState: {
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isAdjustmentRead: false,
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        currentSelectedData: EMPTY_MANUAL_CHARGE_DETAIL_ENTITY,
        editingHeader: EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},
        isShowHeaderInfo: false,
        isShowDetailInfo: false,
        allowAutoRefresh: false,
        updatedRows: [],
        
        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        },
    }
}