import { TariffTypeEntity } from "domain/entity/TariffCode/TariffTypeEntity";
import _ from "lodash";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffTypeRepository } from "./TariffTypeRepo";

export const TariffTypeRepoImpl = (): TariffTypeRepository => {
    const url = '/v1/tariffTypeForComboBox';

    const getAllActiveTariffTypes = async() : Promise<TariffTypeEntity[]> => {
        return axiosGetData(tariffAxiosInstance, url, []).then(res => {
            return _.sortBy(res.data, ["tariffType"]);
        }).catch(err => {
            return [];
        });
    }


    return {
        getAllActiveTariffTypes: getAllActiveTariffTypes,
    }
}