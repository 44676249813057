import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { ShiftRepoImpl } from "domain/repository/Common/ShiftRepoImpl";
import { CoaRepoImpl } from "domain/repository/Company/CoaRepoImpl";
import { CompanyCustomerInfoRepoImpl } from "domain/repository/Company/CompanyCustomerInfoRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { TariffCoaMappingRepoImpl } from "domain/repository/Document/TariffCoaMappingRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { ManualChargeDetailRepoImpl } from "domain/repository/ManualCharge/ManualChargeDetailRepoImpl";
import { ManualChargeHeaderRepoImpl } from "domain/repository/ManualCharge/ManualChargeHeaderRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffProposalRepoImpl } from "domain/repository/TariffCode/TariffProposalRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { NonOpsDetailVM } from "presentation/viewModel/NonOps/NonOpsDetailVM";
import { useMemo } from "react";

export const useNonOpsDetailVM = () => {
    const [, setNonOpsDetailState] = useNonOpsDetailTracked();
    const [, setNonOpsHeaderState] = useNonOpsHeaderTracked();
    const memoNonOpsDetailVM = useMemo(() =>
        NonOpsDetailVM({
            dispatch: [setNonOpsDetailState],
            hdrDispatch: [setNonOpsHeaderState],
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            manualChargeHeaderRepo: ManualChargeHeaderRepoImpl(),
            manualChargeDetailRepo: ManualChargeDetailRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            shiftRepo: ShiftRepoImpl(),
            tariffProposalRepo: TariffProposalRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            companyCustomerInfoRepo: CompanyCustomerInfoRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            tariffCoaMappingRepo: TariffCoaMappingRepoImpl(),
            coaRepo: CoaRepoImpl(),
        }), [setNonOpsDetailState, setNonOpsHeaderState])

    return memoNonOpsDetailVM
}

